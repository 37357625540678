/* @format */

import { mapActions, mapState, mapGetters } from 'vuex'
import { loadExternalScript } from '@/helpers/fileHelper'
import { generateHrefLink, generateStaticHrefLink } from '@/helpers/pageHelper'
import { INTER_DOMAIN } from '@/config/international/config'
import { URL_PROTOCOL } from '@/config'
import { SOLDES_PARAM_TRANSLATIONS } from '@/config/algolia/config'

export default {
  methods: {
    ...mapActions({
      setPageName: 'setPageName',
      setReloadAsyncData: 'listing/setReloadAsyncData',
    }),
    loadABTasty() {
      if (window.ABTasty) {
        return
      }

      loadExternalScript(
        `${
          // Either loading the international version or the default one
          this.isInternationalDisplay ? 'ABTASTY_RYSES' : 'ABTASTY'
        }`,
        // Defer script (true)
        true,
      )
    },
  },
  data() {
    return {
      dataSeo: null,
    }
  },
  // do not put this in created hook as it broke page reloading in tunnel pages
  mounted() {
    this.loadABTasty()
    this.setPageName(this.$route.name)
  },
  created() {
    this.dataSeo = this.seo
  },
  watch: {
    /**
     * Specific case handling soldes and promos routes which should be considered as different pages
     * but where only the query changes
     * */
    '$route.query'(nextQuery, prevQuery) {
      const salesParam = SOLDES_PARAM_TRANSLATIONS[this.currentInterStore.id]

      if (
        salesParam in { ...nextQuery, ...prevQuery } &&
        prevQuery[salesParam] !== nextQuery[salesParam]
      ) {
        this.dataSeo = this.seo
        /**
         * Avoid persistant parameters/hits between promos and sales pages when you come on either one with some parameters already setup (hard reload)
         * Induce some clipping between those two pages. And should be improved
         * WatchQuery with an array of SOLDES_PARAM_TRANSLATIONS should be used.
         * Nevertheless, dataSeo is null in this situation because the component is not recreated.
         * We could invistigate to remove this.dataSeo (using directly this.seo instead), but at the moment it creates glitches.
         * https://github.com/laboutiqueofficielle/nuxt-front/issues/100
         * that can't be removed ()
         * */
        // FIXME: clipping between promos and sales when hard loading one with parameters/filters setup and navigating to the other
        this.$nuxt.refresh()
      }
    },
    reloadAsyncData() {
      if (this.reloadAsyncData) {
        this.$nuxt.$loading.finish()
        this.setReloadAsyncData(false)
      }
    },
  },
  computed: {
    ...mapState({
      seo: (state) => state.seo,
      currentInterStore: (state) => state.international.currentInterStore,
      availableInterStore: (state) => state.international.availableInterStore,
      reloadAsyncData: (state) => state.listing.reloadAsyncData,
      globalPageInfo: (state) => state.globalPageInfo,
    }),
    ...mapGetters({
      isInternationalDisplay: 'international/isInternationalDisplay',
    }),
    headData() {
      let hrefLink = []
      hrefLink =
        this.$route.path === '/'
          ? generateStaticHrefLink(
              this.currentInterStore.id,
              this.availableInterStore,
            )
          : generateHrefLink(
              {
                ...this.$route,
                domain: `${URL_PROTOCOL}://${INTER_DOMAIN[this.currentInterStore.id]}`,
              },
              this.globalPageInfo,
            )

      const faviconFolder = this.isInternationalDisplay ? 'other' : 'fr'

      const head = {
        title: this.dataSeo?.title,
        htmlAttrs: {
          lang: this.currentInterStore.langCode,
        },
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.dataSeo?.description,
          },
          {
            hid: 'viewport',
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1.0,  shrink-to-fit=no, maximum-scale=1.0,user-scalable=0',
          },
        ],
        link: [
          {
            rel: 'icon',
            sizes: 'any',
            href: `/favicon/${faviconFolder}/favicon.ico`,
          },
          {
            rel: 'icon',
            type: 'image/svg+xml',
            href: `/favicon/${faviconFolder}/favicon.svg`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: `/favicon/${faviconFolder}/apple-touch-icon.png`,
          },
          {
            rel: 'mask-icon',
            color: '#e9252f',
            href: `/favicon/${faviconFolder}/safari-pinned-tab.svg`,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: `/favicon/${faviconFolder}/favicon-32x32.png`,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: `/favicon/${faviconFolder}/favicon-16x16.png`,
          },
          ...hrefLink,
        ],
        script: [],
      }
      return head
    },
  },
  head() {
    return this.headData
  },
}
