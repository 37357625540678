import { EXTERNAL_SCRIPT, PROVIDER_SCRIPT } from '@/config'

export function loadExternalScript (name, defer = false, callback) {
  const externalScript = EXTERNAL_SCRIPT[name]
  const customEvent = new Event(`${name}-SCRIPT-LOADED`)
  const s = document.createElement('script')
  s.defer = defer

  s.setAttribute('src', externalScript)
  document.head.appendChild(s)
  s.onload = function () {
    document.dispatchEvent(customEvent)

    typeof callback === 'function' && callback()
  }
}

export function downloadPDF (data, filename) {
  const fileLink = document.createElement('a')
  const fileLinkUrl = window.URL.createObjectURL(data)
  fileLink.href = fileLinkUrl
  fileLink.setAttribute('download', filename)
  document.body.appendChild(fileLink)
  fileLink.click()
  document.body.removeChild(fileLink)
  window.URL.revokeObjectURL(fileLink.href)
}

export function loadExternalProviderScript (providerName) {
  const customEvent = new Event('providerLoaded')
  const s = document.createElement('script')

  s.setAttribute('src', PROVIDER_SCRIPT.add[providerName])
  document.head.appendChild(s)
  s.onload = function () {
    document.dispatchEvent(customEvent)
  }
}
export function removeExternalProvider (providerName) {
  const scriptToRemove = document.querySelectorAll(`[src^='${PROVIDER_SCRIPT.remove[providerName]}']`)
  scriptToRemove.forEach((script) => {
    script.parentNode.removeChild(script)
  })
}
