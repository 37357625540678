<template>
  <div class="c-slider">
    <div v-if="slides && slides.length > 1" class="swiper">
      <div ref="slider" class="swiper-wrapper">
        <div
          v-for="(slide, index) in slides"
          :key="slide.id"
          class="swiper-slide"
        >
          <nuxt-link
            :to="slide.link"
            :title="slide.title"
            event=""
            @click.native="trackingClickEndRedirect(slide)"
          >
            <picture>
              <source
                media="(max-width: 1024px)"
                :srcset="slide.imageMobile"
              >
              <img
                :src="slide.image"
                :alt="slide.altText"
                :loading="lazyLoad && index === 0 ? 'eager' : 'lazy'"
                :fetchPriority="addHighPriorityOnFirstSlide && index === 0 ? 'high' : 'low'"
                width="1366"
                height="680"
              >
            </picture>
          </nuxt-link>
        </div>
      </div>
      <button v-if="slides && slides.length > 1" ref="prev" class="swiper-button-prev">
        <PrevIcon :title="$t('components.molecules.global.slider.prev')" />
      </button>
      <button v-if="slides && slides.length > 1" ref="next" class="swiper-button-next">
        <NextIcon :title="$t('components.molecules.global.slider.next')" />
      </button>
    </div>
    <div
      v-else
      class="c-slider__placeholder"
    >
      <nuxt-link
        :to="slides[0].link"
        :title="slides[0].title"
        event=""
        @click.native="trackingClickEndRedirect(slides[0])"
      >
        <picture>
          <source
            media="(max-width: 1024px)"
            :srcset="slides[0].imageMobile"
          >
          <img
            :src="slides[0].image"
            :alt="slides[0].altText"
            loading="eager"
            fetchPriority="high"
            width="1366"
            height="680"
          >
        </picture>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { createTrackPromotionEvent } from '@/helpers/gtmHelper'

// core version + navigation, pagination modules:
import { Swiper, Navigation, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import NextIcon from '@/components/atoms/icons/Next'
import PrevIcon from '@/components/atoms/icons/Prev'

export default {
  components: { NextIcon, PrevIcon },
  props: {
    slides: {
      type: Array,
      default: null
    },
    promotionData: {
      type: Object,
      default: null
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    lazyLoad: {
      type: Boolean,
      default: false
    },
    addHighPriorityOnFirstSlide: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      swiper: null,
      activeSlide: 1,
      animateImg: false
    }
  },
  computed: {
    ...mapState({
      readyToTrack: state => state.gtm && state.gtm.readyToTrack
    })
  },
  watch: {
    readyToTrack () {
      this.startTrackingViewPromotion()
    }
  },
  mounted () {
    // configure Swiper to use modules. The modules were tested with SwiperJS v6.8.4 with NuxtJS v2.15.7
    // previously it was before export default. Moved here for performance issues. Move back in case of problems.
    // add or remove unused modules
    Swiper.use([Navigation, Autoplay])

    // init Swiper:
    /* eslint-disable-next-line no-unused-vars */
    this.swiper = new Swiper('.swiper', {
      // Optional parameters
      // @see https://swiperjs.com/swiper-api#parameters
      direction: 'horizontal',
      loop: true,
      speed: 500,
      // remove unused modules if needed
      modules: [Navigation, Autoplay],

      // Autoplay if needed
      autoplay: {
        delay: 5000
      },
      // Navigation arrows if needed
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
      // Configure other options. Not tested
    })

    this.startTrackingViewPromotion()
  },
  methods: {
    startTrackingViewPromotion () {
      // if (this.promotionData?.length && this.readyToTrack) {
      //   this.$gtm.trackEvent(createTrackPromotionEvent('view_promotion', this.promotionData))
      // }
    },
    trackingClickEndRedirect (slide) {
      // if (this.promotionData) {
      //   this.$gtm.trackEvent(
      //     createTrackPromotionEvent('select_promotion', this.promotionData)
      //   )
      // }

      // event empty on nuxt-link
      // https://stackoverflow.com/questions/59885664/nuxt-how-to-prevent-nuxt-link-goes-to-another-page

      this.$gtm.trackEvent({
        event: 'lbo_slider',
        page_data: {
          slider_numb: slide.position,
          alt: slide.altText
        }
      })
      this.$router.push({ path: this.localePath(slide.link) })
    },
    updateVisibleSlide () {
      this.slider.events.on('indexChanged', () => {
        this.activeSlide = this.slider.getInfo().displayIndex
      })
    },
    getTabIndex (index) {
      return (this.activeSlide === index + 1) ? 0 : -1
    }
  }
}
</script>

<style lang="scss" scoped>
.c-slider {
  $c: &;

  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 6rem;
  background-color: $grey-1300;

  &__placeholder {
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__alone {
    button {
      display: block;

      &:focus-visible {
        opacity: 0.8;
      }
    }
  }

  @include mq($until: tablet) {
    margin-bottom: 3.5rem;
  }

  .swiper {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    &::after {
      content: none;
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 2.2rem;
  }

  .swiper-button-next {
    right: 2.2rem;
  }
}

.layout-leave-active {
  // stylelint-disable-next-line selector-class-pattern
  .c-slider__wrapper {
    width: 100%;

    > div {
      &:not(:first-child) {
        display: none;
      }
    }
  }
}
</style>
