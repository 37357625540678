<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="50"
  >
    <title>{{ title }}</title>
    <defs>
      <clipPath id="p80la">
        <path
          fill="#fff"
          d="M1.746.99L16.011 25 1.746 49.01"
        />
      </clipPath>
    </defs>
    <path
      fill="none"
      stroke="#fff"
      stroke-miterlimit="50"
      stroke-width="4"
      d="M1.746.99v0L16.011 25v0L1.746 49.01v0"
      clip-path="url(&quot;#p80la&quot;)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
