<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="50"
  >
    <title>{{ title }}</title>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M16.282 49.01L1.989 25 16.282.99"
        />
      </clipPath>
    </defs>
    <path
      fill="none"
      stroke="#fff"
      stroke-miterlimit="50"
      stroke-width="4"
      d="M16.282 49.01L1.989 25 16.282.99"
      clip-path="url(&quot;#a&quot;)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
