/* @format */

import {
  getUiStateByPage,
  resetSkeletonStoreValues,
} from '@/helpers/skeletonHelper'

export default {
  beforeRouteEnter(to, _from, next) {
    // This hook is mandatory to keep the "true state" of the header color
    // This is because the user is allowed to "cancel navigation between pages"
    // eg. (click back to home when navigating on listing for eg) --> no lifecycle hooks are triggered
    // This hack provide a way to ensure we trully navigated to the destination page
    if (process.server || !window.$nuxt?.$store) {
      next()
      return
    }

    // This is a very "hacky" way of doing things, maybe there's a better way to handle the behavior
    const { transparentHeader } = getUiStateByPage(to.name, {
      transparentHeader: window.$nuxt?.$store?.state.ui.transparentHeader,
    })

    window.$nuxt.$store.commit(
      'ui/UPDATE_TRANSPARENT_HEADER_INITIAL_VALUE',
      transparentHeader,
    )
    next()
  },

  computed: {
    skeleton() {
      return this.$store.state.ui.skeleton
    },
    pageLoading() {
      return this.$store.state.ui.pageLoading
    },
    asyncDataLoading() {
      return this.$store.state.ui.asyncDataLoading
    },
  },

  data() {
    return {
      hasQueryChanged: false,
    }
  },

  watch: {
    $route() {
      this.hasQueryChanged = true

      // Prevent pages that have query parameters changes to be nav-lock (not getting through asyncData)
      if (!this.asyncDataLoading) {
        this.resetSkeletonToPageDestinationState()

        this.hasQueryChanged = false
      }
    },

    asyncDataLoading(isLoading) {
      // Prevent pages that are the same but identified by a different query to be nav-lock (getting through asyncData)
      if (this.hasQueryChanged && !isLoading) {
        this.resetSkeletonToPageDestinationState()

        this.hasQueryChanged = false

        return
      }

      const SKELETON_TIMEOUT = process.env.SKELETON_TIMEOUT

      if (!isLoading) {
        /* We need a timeout for the skeleton in case the user navigate too fast from one page to another
         ** (the middleware is no triggering in that case but asyncData is) */
        const to = setTimeout(() => {
          if (this.$store.state.ui.pageLoading) {
            this.resetSkeletonToPageDestinationState()
          }
        }, SKELETON_TIMEOUT)
        this.$store.commit('ui/UPDATE_TIMEOUT_ID', to)
      }
    },
  },

  methods: {
    resetSkeletonToPageDestinationState() {
      const { skeleton, spacer, transparentHeader } = getUiStateByPage(
        this.$route.name,
      )

      resetSkeletonStoreValues(this.$store, {
        UPDATE_SPACER: spacer,
        UPDATE_SKELETON: skeleton,
        UPDATE_TRANSPARENT_HEADER: transparentHeader,
        UPDATE_LOADING: false,
      })
    },
  },

  created() {
    if (process.client) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      this.$store.commit('ui/UPDATE_LOADING', false)
    }
  },
}
